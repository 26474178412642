import React from "react";
import { Navbar } from "../components";
import { Contact } from "../containers";
import NotFoundPageContent from "../components/notFoundPage/NotFoundPageContent";

function NoutFoundPage() {
  return (
    <div>
      <div className="z-top">
        <Navbar />
      </div>
      <NotFoundPageContent />
      <Contact />
    </div>
  );
}

export default NoutFoundPage;
