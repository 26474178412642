import React from "react";
import { Navbar } from "../components";
import ContactPageContent from "../components/contactPage/ContactPageContent";
import { Contact } from "../containers";

function ContactPage() {
  return (
    <div>
      <div className="z-top">
        <Navbar />
      </div>
      <ContactPageContent />
      <Contact contactOption="V prípade akýchkoľvek otázok nás môžete kontaktovať telefonicky alebo emailom tu!"/>
    </div>
  );
}

export default ContactPage;
