import React, { useEffect, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import emailjs from "@emailjs/browser";
import {
  ErrorText,
  ReactHookFormInput,
  ReactHookFormSelect,
} from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "./form.css";
import { format } from "date-fns";
import { Checkbox } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

function Form({ register, setValue, reset, errors, handleSubmit, watch }) {
  const form = useRef();
  const [quantity, courseType] = watch(["quantity", "courseType"]);
  const individualPricing = [
    { value: "10 hodín", label: "10 hodín - €220" },
    { value: "20 hodín", label: "20 hodín - €400" },
    { value: "30 hodín", label: "30 hodín - €580" },
  ];
  const [prices, setPrices] = useState(individualPricing);
  const [border, setBorder] = useState("1px dashed black");
  const specialRules = {
    phonenumber: {
      required: "Telefónne číslo je povinné!",
      pattern: {
        value: /^\+(?:[0-9]●?){6,14}[0-9]$/,
        message: "Uveďte svoje telefónne číslo s medzinárodnou predvoľbou.",
      },
    },
    email: {
      required: "E-mail je povinný!",
      pattern: {
        value:
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
        message: "Uistite sa, že ste zadali platnú e-mailovú adresu.",
      },
    },
    course: { required: "Jazyk je povinný!" },
    name: { required: "Meno je povinné!" },
    lastname: { required: "Priezvisko je povinné!" },
    jazyk: { required: "Jazyk je povinný!" },
    typKurzu: { required: "Typ kurzu je povinný!" },
    quantity: {
      required: courseType !== "Ukážkova" ? "Počet hodín je povinný!" : false,
    },
    proficiency: { required: "Úroveň je povinná!" },
    location: { required: "Spôsob výučby je povinný!" },
  };

  const showToastMessage = (style) => {
    if (style === "succes")
      toast.success(
        "Vaša registrácia prebehla úspešne! Na váš email bola odoslaná potrvdzujúca správa.",
        { position: toast.POSITION.BOTTOM_CENTER }
      );
    if (style === "error")
      toast.error(
        "Niečo sa pokazilo! Skúste to znova alebo nám pošlite email na info@bratilingua.sk.",
        { position: toast.POSITION.BOTTOM_CENTER }
      );
  };

  const generateUniqueRandomInt = () => {
    const randomPart = Math.floor(Math.random() * 100000000);
    const timestampPart = new Date().getTime();
    const uniqueRandomInt = parseInt(
      `${timestampPart}${randomPart}`.slice(0, 8)
    );
    return uniqueRandomInt;
  };

  const onSubmit = (data, e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_u9uy4lg",
        courseType === "Ukážkova" ? "template_yszl4hm" : "template_5qya1l7",
        form.current,
        "xuXCBlkR2IoX7u5LA"
      )
      .then(
        (result) => {
          showToastMessage("succes");
          reset();
          setValue("course", "");
          setValue("courseType", "");
        },
        (error) => {
          showToastMessage("error");
        }
      );
  };

  const add15days = () => {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + 15);
    return futureDate;
  };

  const addExtraFieldsToForm = (amountClasses, courseType) => {
    if (courseType === "Individuálny") {
      if (amountClasses === "10 hodín") setValue("price", "220");
      if (amountClasses === "20 hodín") setValue("price", "400");
      if (amountClasses === "30 hodín") setValue("price", "580");
    }

    if (courseType === "Skupinový") {
      if (amountClasses === "10 hodín") setValue("price", "120");
      if (amountClasses === "20 hodín") setValue("price", "100");
      if (amountClasses === "30 hodín") setValue("price", "80");
    }
    setValue("datefrom", format(new Date(), "dd.MM.yyyy"));
    setValue("dateto", format(add15days(), "dd.MM.yyyy"));
    setValue("facnr", generateUniqueRandomInt());
  };

  const updatePricing = (courseType) => {
    if (courseType === "Individuálny") setPrices(individualPricing);
    if (courseType === "Skupinový")
      setPrices([
        { value: "10 hodín", label: "10 hodín - €120" },
        { value: "20 hodín", label: "20 hodín - €100" },
        { value: "30 hodín", label: "30 hodín - €80" },
      ]);
  };

  useEffect(() => {
    if (quantity && courseType) addExtraFieldsToForm(quantity, courseType);
  }, [quantity]);

  useEffect(() => {
    if (courseType) updatePricing(courseType);
  }, [courseType]);

  useEffect(() => {
    if (errors.terms) setBorder("2px dashed red");
    else setBorder("2px dashed black");
  }, [errors]);

  return (
    <form ref={form} onSubmit={handleSubmit(onSubmit)} className="bl_form">
      <div className="form_section" style={{ display: "none" }}>
        <div className="bl_form-fieldContainer">
          <ReactHookFormInput
            id="price"
            name="price"
            label="price"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            multiline={false}
            type="number"
          />
        </div>
        <div className="bl_form-fieldContainer">
          <ReactHookFormInput
            id="datefrom"
            name="datefrom"
            label="datefrom"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            multiline={false}
            type="text"
          />
        </div>
        <div className="bl_form-fieldContainer">
          <ReactHookFormInput
            id="dateto"
            name="dateto"
            label="dateto"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            multiline={false}
            type="text"
          />
        </div>
        <div className="bl_form-fieldContainer">
          <ReactHookFormInput
            id="facnr"
            name="facnr"
            label="facnr"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            multiline={false}
            type="text"
          />
        </div>
      </div>
      <div className="form_section">
        <div className="bl_form-fieldContainer">
          {errors?.firstname && (
            <ErrorText message={errors.firstname.message} />
          )}
          <ReactHookFormInput
            id="firstname"
            name="firstname"
            label="Meno"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            multiline={false}
            type="text"
            rules={specialRules.name}
          />
        </div>
        <div className="bl_form-fieldContainer">
          {errors?.lastname && <ErrorText message={errors.lastname.message} />}
          <ReactHookFormInput
            id="lastname"
            name="lastname"
            label="Priezvisko"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            multiline={false}
            type="text"
            rules={specialRules.lastname}
          />
        </div>
      </div>
      <div className="form_section">
        <div className="bl_form-fieldContainer">
          {errors?.phonenumber && (
            <ErrorText message={errors.phonenumber.message} />
          )}
          <ReactHookFormInput
            id="phonenumber"
            name="phonenumber"
            label="Číslo: +421 ..."
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            multiline={false}
            type="text"
            rules={specialRules.phonenumber}
          />
        </div>
        <div className="bl_form-fieldContainer">
          {errors?.email && <ErrorText message={errors.email.message} />}
          <ReactHookFormInput
            id="email"
            name="email"
            label="E-mail"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            multiline={false}
            type="email"
            rules={specialRules.email}
          />
        </div>
      </div>
      <div className="form_section">
        <div className="bl_form-fieldContainer">
          {errors?.course && <ErrorText message={errors.course.message} />}
          <ReactHookFormSelect
            id="course"
            name="course"
            label="Jazyk"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            rules={specialRules.course}
          >
            <MenuItem value="">Vybrať</MenuItem>
            <MenuItem value="Anglický jazyk">Anglický jazyk</MenuItem>
            <MenuItem value="Španielsky jazyk">Španielsky jazyk</MenuItem>
          </ReactHookFormSelect>
        </div>
        <div className="bl_form-fieldContainer">
          {errors?.courseType && (
            <ErrorText message={errors.courseType.message} />
          )}
          <ReactHookFormSelect
            id="courseType"
            name="courseType"
            label="Typ lekcií"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            rules={specialRules.typKurzu}
          >
            <MenuItem value="">Vybrať</MenuItem>
            <MenuItem value="Individuálny">Individuálny</MenuItem>
            <MenuItem value="Skupinový">Skupinový</MenuItem>
            <MenuItem value="Ukážkova">Ukážková hodina</MenuItem>
          </ReactHookFormSelect>
        </div>
      </div>
      <div className="form_section">
        <div className="bl_form-fieldContainer">
          {errors?.proficiency && (
            <ErrorText message={errors.proficiency.message} />
          )}
          <ReactHookFormSelect
            id="proficiency"
            name="proficiency"
            label="Úroveň"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            rules={specialRules.proficiency}
          >
            <MenuItem value="">Vybrať</MenuItem>
            <MenuItem value="A1">A1</MenuItem>
            <MenuItem value="A2">A2</MenuItem>
            <MenuItem value="B1">B1</MenuItem>
            <MenuItem value="B2">B2</MenuItem>
            <MenuItem value="C1">C1</MenuItem>
            <MenuItem value="C2">C2</MenuItem>
          </ReactHookFormSelect>
        </div>
        <div className="bl_form-fieldContainer">
          {errors?.location && <ErrorText message={errors.location.message} />}
          <ReactHookFormSelect
            id="location"
            name="location"
            label="Spôsob výučby"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            rules={specialRules.location}
          >
            <MenuItem value="">Vybrať</MenuItem>
            <MenuItem value="osobne">Osobne</MenuItem>
            <MenuItem value="online">Online</MenuItem>
          </ReactHookFormSelect>
        </div>
        <div className="bl_form-fieldContainer">
          {errors?.quantity && <ErrorText message={errors.quantity.message} />}
          <ReactHookFormSelect
            id="quantity"
            name="quantity"
            label="Počet hodín"
            register={register}
            defaultValue=""
            variant="outlined"
            margin="none"
            rules={specialRules.quantity}
            disabled={!courseType || courseType === "Ukážkova"}
          >
            <MenuItem value="">Vybrať</MenuItem>
            {prices.map((price, index) => (
              <MenuItem key={index} value={price.value}>
                {price.label}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
        </div>
      </div>
      <div className="bl_form-fieldContainer messageField">
        <ReactHookFormInput
          id="message"
          name="message"
          label="Správa"
          register={register}
          defaultValue=""
          variant="outlined"
          margin="none"
          multiline={true}
          type="text"
        />
      </div>
      <div className="bl_form-fieldContainer">
        <FormGroup
          style={{
            alignItems: "center",
            justifyItems: "center",
            marginTop: "2rem",
            border: border,
          }}
        >
          {errors?.terms && (
            <ErrorText
              className="bl_errorText_checkbox"
              message={errors.terms.message}
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                {...register("terms", { required: "Zaškrtnite prosím" })}
              />
            }
            label="vyplnením a odoslaním formuláru súhlasíte s našimi obchodnými podmienkami a GDPR"
          />
        </FormGroup>
      </div>
      <div className="buttonWrapper">
        <button type="submit" className="button-submit">
          Objednať
        </button>
      </div>
      <ToastContainer />
    </form>
  );
}

export default Form;
