import React from "react";
import "./polaroid.css";
import { Img } from "react-image";

function Polaroid({ tName, captionTitle, captionDescription, src, alt, width }) {

  return (
    <figure className="bl_polaroid">
      <Img src={src} alt={alt} width={width} />
      <figcaption>
        <p className="bl_polaroid-tName">{tName}</p>
        <p className="bl_polaroid-title">{captionTitle}</p>
        <p className="bl_polaroid-description">{captionDescription}</p>
      </figcaption>
    </figure>
  );
}

export default Polaroid;
