import React, { useEffect } from "react";
import { HeadLine } from "../../components";
import "animate.css";
import "./header.css";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/utils";

const Header = () => {
  const masks = [
    "p",
    "o",
    "d",
    "d-accent",
    "t-first",
    "e",
    "s-first",
    "a-first",
    "s-second",
    "n",
    "a-second",
    "m",
    "i-first",
    "u",
    "c",
    "i-second",
    "t-second",
    "t-second-accent",
    "explenationmark",
  ];

  useEffect(() => {
    if (!window.sessionStorage.getItem("animated")) {
      masks.forEach((mask) => {
        const id = `#mask-${mask}`; // Prepend #mask- to each mask element name
        const path = document.querySelector(id);

        if (path) {
          const length = path?.getTotalLength(); // Calculate the length of a path
          path.style.strokeDasharray = length; // Set the length to stroke-dasharray in the styles
          path.style.strokeDashoffset = length; // Set the length to stroke-dashoffset in the styles
        }
      });

      window.sessionStorage.setItem("animated", 1);
      document
        .getElementsByClassName("bl__header-content_text")[0]
        .classList.add("animate__delay-1s");
    }
  }, []);

  return (
    <div className="bl__header section__padding" id="home">
      <div className="bl__header-content">
        <h1><HeadLine /></h1>
        <div className="bl__header-content_text animate__animated animate__fadeInLeft">
          <p>
            Zdokonaľte sa v angličtine a španielčine prostredníctvom našich
            jazykových kurzov! Naši skúsení lektori vám poskytnú individuálnu výučbu
            jazyka a pomôžu vám dosiahnuť plynulosť a sebavedomie. Či už ste
            začiatočník alebo pokročilý študent, ponúkame vám kurzy prispôsobené
            vašim potrebám a záujmom. Začnite svoju jazykovú cestu ešte dnes a
            pridajte sa k našej komunite študentov z celého Slovenska!
          </p>
          <div className="bl__header-content__buttons">
            <a href="#courses">
              <button type="button" className="button-secondary">
                Ponuka kurzov
              </button>
            </a>
            <Link to={"/registracia"} onClick={scrollToTop}>
              <button type="button" className="button-primary">
                Prihláste sa
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
