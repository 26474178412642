import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const MyLazyLoadedImage = ({ imageSrc, altText, imageprops }) => {
    return (
        <LazyLoadImage
            src={imageSrc}
            alt={altText}
            effect="blur"
            height="auto"
            width="100%"
        />
    );
};

export default MyLazyLoadedImage;