const photos = [
    // {
    //     src: require("../../assets/gallery/bratilingua-office-outside-front.jpg"),
    //     width: 599,
    //     height: 798,
    //     title: false
    // },
    {
        src: require("../../assets/gallery/class-english-board.jpg"),
        width: 800,
        height: 695,
        title: false
    },
    {
        src: require("../../assets/gallery/online-class-1.jpg"),
        width: 800,
        height: 500,
        title: false
    },
    {
        src: require("../../assets/gallery/online-class-2.jpg"),
        width: 800,
        height: 500,
        title: false
    },
    {
        src: require("../../assets/gallery/desk_view.jpg"),
        width: 472,
        height: 794,
        title: false
    },
    {
        src: require("../../assets/gallery/logo_on_wall_with_plants.jpg"),
        width: 531,
        height: 932,
        title: false
    },
    {
        src: require("../../assets/gallery/new_big_board_and_desk.jpg"),
        width: 605,
        height: 807,
        title: false
    },
    {
        src: require("../../assets/gallery/Petrzalka-leaflet.jpg"),
        width: 625,
        height: 888,
        title: "https://www.centrumprerodiny.sk/"
    },
    {
        src: require("../../assets/gallery/bratilingua-new-office-outside.jpg"),
        width: 800,
        height: 600,
        title: false
    },
    {
        src: require("../../assets/gallery/BL_client_in_office_1.jpg"),
        width: 700,
        height: 933,
        title: false
    },
];

export default photos;