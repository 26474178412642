import React from "react";
import { Navbar } from "../components";
import { Contact } from "../containers";
import GalleryPageContent from "../components/galleryPage/GalleryPageContent";

function GalleryPage() {
  return (
    <div>
      <div className="z-top">
        <Navbar />
      </div>
      <GalleryPageContent />
      <Contact />
    </div>
  );
}

export default GalleryPage;
