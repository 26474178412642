import React from "react";
import "./partners.css";
import zlavadnaLogo from "../../assets/partners/logo-zd_black-transp_rgb.png";
import zlavomatLogo from "../../assets/partners/Zlavomat Logo Light@3x.png";
import { Img } from "react-image";


const Partners = () => {
  return (
    <div className="bl_partners section__padding section_shadow">
      <div className="bl_partners-container">
        <h2>Naši partneri</h2>
        <div className="flex-row justify-around">
          <a href="https://www.zlavadna.sk/" target="_blank" rel="noopener noreferrer">
            <Img width="60%" src={zlavadnaLogo} alt="Zlavadna logo" />
          </a>
          <a href="https://www.zlavomat.sk/" target="_blank" rel="noopener noreferrer">
            <Img width="100%" src={zlavomatLogo} alt="Zlavomat logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Partners;
