import React from 'react';
import "./notFoundPage.css";

const NotFoundPageContent = () => {

  return (
    <div className='bl_nf-wrapper section__padding-page'>
      <span>404</span>
      <h1>Je nám ľúto, táto stránka neexistuje.</h1>
    </div>
  )
}

export default NotFoundPageContent
