import React from "react";
import "./icons.css";

const CurvedArrow = () => {
  return (
    <svg
      className="bl_icons-curvedArrow"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="256"
      height="256"
      viewBox="0 0 256 256"
      xmlSpace="preserve"
    >
      <defs></defs>
      <g
        css="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
      >
        <path
          d="M 5.873 12.409 l 8.53 4.925 l 8.53 4.925 c 0.914 0.528 2.056 -0.132 2.056 -1.187 l 0 -6.207 c 13.935 1.705 24.766 13.603 24.766 27.991 l 0 12.12 c 0 18.8 14.876 34.192 33.475 35.023 c 0.859 0.038 1.583 -0.632 1.583 -1.491 v -3.871 c 0 -0.785 -0.608 -1.448 -1.391 -1.486 C 68.51 82.423 56.603 70.063 56.603 54.976 l 0 -12.12 c 0 -18.17 -13.894 -33.152 -31.615 -34.887 l 0 -6.595 c 0 -1.055 -1.142 -1.714 -2.056 -1.187 l -8.53 4.925 l -8.53 4.925 C 4.959 10.562 4.959 11.881 5.873 12.409 z"
          css="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
      </g>
    </svg>
  );
};

export default CurvedArrow;
