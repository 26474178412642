import React, { useEffect } from "react";
import "./courses.css";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/utils";

const Courses = () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      console.log(entry, "OBSERVE");
      if (entry.isIntersecting) entry.target.classList.add("show");
      else entry.target.classList.remove("show");
    });
  });

  useEffect(() => { 
    const hiddenElements = document.querySelectorAll("hidden");
    hiddenElements?.forEach((el) => observer.observe(el));
  }, []);

  return (
    <div id="courses" className="bl_courses section_shadow">
      <div className="bl_courses-content_container section__padding">
        <div className="bl_courses-content_container-course">
          <figure className="english"/>
          <h2>Angličtina</h2>
          <Link to={"/kurzy/anglicky-jazyk"} onClick={scrollToTop}>
            <button className="bl_courses-content_container-course_button">Zobraziť viac</button>
          </Link>
        </div>
        <div className="bl_courses-content_container-course">
          <figure className="spanish"/>
          <h2>Španielčina</h2>
          <Link to={"/kurzy/spanielsky-jazyk"} onClick={scrollToTop}>
            <button className="bl_courses-content_container-course_button">Zobraziť viac</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Courses;
