export const teacherImages = [
  {
    name: "Karin",
    captionTitle: "Študijná riaditeľka",
    captionDescription: "Karin vyštudovala anglický a španielsky jazyk a ich výučbe sa venuje už niekoľko rokov. Vyučuje najmä pokročilejších študentov a firemné kurzy.",
    src: require("../../assets/teachers/karin.jpg"),
    alt: "učiteľka angličtiny a španielčiny - Karin",
    title: "Picture of our english - spanish teacher Karin."
  },
  {
    name: "Paulína",
    captionTitle: "Lektorka",
    captionDescription: "Paula vyštudovala odbor prekladateľstvo a tlmočníctvo v kombinácii anglický a španielsky jazyk. Má bohaté skúsenosti s vedením detských jazykových kurzov, ale do tajov angličtiny a španielčiny rada zasvätí aj dospelých.",
    src: require("../../assets/teachers/paula.jpg"),
    alt: "učiteľka angličtiny a španielčiny - Paulina",
    title: "Picture of our english - spanish teacher Paulina."
  },
  {
    name: "Natália",
    captionTitle: "Lektorka",
    captionDescription: "Natália je študentkou práva v Bratislave. Jej veľkým záujmom už od detstva je učenie sa cudzích jazykov. Na svojich hodinách používa moderné učebné metódy, ktoré už pomohli mnohým študentom naučiť sa po anglicky a rada pomôže aj tebe.",
    src: require("../../assets/teachers/natalia.jpg"),
    alt: "učiteľka angličtiny - Natália",
    title: "Picture of our english teacher Natália."
  },
  {
    name: "Adela",
    captionTitle: "Lektorka",
    captionDescription: "Adela študuje politológiu a medzinárodné vzťahy. K cudzím jazykom má však odjakživa blízko a učenie sa stalo jej vášňou. Jazyk vníma ako kľúč k novým kultúram, zážitkom či príležitostiam, o ktorý sa rada podelí s každým študentom.",
    src: require("../../assets/teachers/adela.jpg"),
    alt: "učiteľka angličtiny - Adela",
    title: "Picture of our english teacher Adela."
  },
];
