import React from "react";
import "./aboutPage.css";
import { teacherImages } from "./teacherImages";
import Polaroid from "../polaroid/Polaroid";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/utils";

function About() {
  return (
    <div className="bl_about-wrapper section__padding-page">
      <h1>O nás</h1>
      <div className="bl_about-content_head">
        <div>
          <p>
            V <b>Bratilingue</b>, vám s nadšením pomáhame sa zlepšovať v angličtine a španielčine. Naším poslaním je umožniť vám sebavedomo sa vyjadrovať v oboch jazykoch, či už ste začiatočník, alebo sa snažíte zlepšiť si svoju plynulosť.
          </p>

          <p style={{ margin: 0 }}>
            Ceníme si vašu individualitu ako študenta.
            Poskytujeme individuálnu pozornosť a poradenstvo, aby sme splnili
            vaše špecifické potreby.
          </p>

          <p>
            <Link className="bl_about-content_head-link" onClick={scrollToTop}>
              PRIPOJTE SA K NÁM
            </Link>
            a zažite prostredie, v ktorom je učenie príjemné.
            Kontaktujte nás ešte dnes, aby ste sa dozvedeli viac a dosiahli
            svoje jazykové ciele. Tešíme sa, že túto cestu jazykového
            vzdelávania začneme spolu s vami!
          </p>
        </div>
        <div className="bl_about-content_head-office div-shadow">
          <h3>Naučte sa cudzí jazyk v našej kancelárii v srdci Bratislavy!</h3>
          <p>Nájdete nás na Americkom námestí na ulici Odborárske námestie 3. Vchod sa nachádza z Májkovej ulice.</p>
        </div>
      </div>
      <div className="bl_about-content_teachers">
        <h2>Náš tím 👋</h2>
        <div className="bl_about-content_teachers-polaroids">
          {teacherImages.map((teacher, i) => (
            <Polaroid
              key={i}
              tName={teacher.name}
              captionTitle={teacher.captionTitle}
              captionDescription={teacher.captionDescription}
              src={teacher.src}
              alt={teacher.alt}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default About;
