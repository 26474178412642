import React from "react";
import { Gallery, Contact, SignUp, Header, Partners, Review } from "./containers";
import { Brand, Navbar } from "./components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowAltCircleLeft, faCaretSquareDown, faCircleXmark, faCircleCheck, faEnvelope, faContactBook, faSquareCaretRight, faSquareCaretLeft } from "@fortawesome/free-regular-svg-icons";
import "./App.css";

library.add( faArrowAltCircleLeft, faCaretSquareDown, faCircleXmark, faCircleCheck, faEnvelope, faContactBook, faSquareCaretRight, faSquareCaretLeft );

const App = () => {
  return (
    <div className="App">
      <div className="z-top">
        <Navbar />
        <Header />
      </div>
      <Brand />
      <Gallery />
      <Review />
      <Partners />
      <SignUp />
      <Contact />
    </div>
  );
};

export default App;
